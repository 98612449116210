import React from 'react'
import styled from 'styled-components'
import {Link} from 'gatsby'
import Img from 'gatsby-image'

const Post = styled.div`
  border: 1px solid ${props => props.theme.colors.secondary};
  border-radius: 2px;
  margin: 0 0 1em 0;
  width: 100%;
  transition: background 0.2s;
  @media screen and (min-width: ${props => props.theme.responsive.small}) {
    flex: 0 0 49%;
    margin: 0 0 2vw 0;
  }
  @media screen and (min-width: ${props => props.theme.responsive.medium}) {
    flex: 0 0 32%;
  }
  &:hover {
    h2{
        color: ${props => props.theme.colors.base};
    }
  }
  .img-container {
    display: flex;
    flex-flow: column;
    height: 100%;
    width: 100%;
    color: ${props => props.theme.colors.base};
    text-decoration: none;
    .gatsby-image-wrapper {
      height: 0;
      padding-bottom: 60%;
    }
  }
`


const GalleryCard = props => {
    return (
        <Post onClick={(e) => props.onClick(e)}>
            <div className="img-container">
                <Img sizes={props.image.sizes} backgroundColor={'#eeeeee'}/>
            </div>
        </Post>
    )
}

export default GalleryCard
