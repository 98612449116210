import React from 'react'
import CardList from '../components/CardList'
import Container from '../components/Container'
import styled from "styled-components";
import Layout from '../components/DefaultLayout';
import {graphql} from "gatsby"
import GalleryCard from "../components/GalleryCard";
import Lightbox from 'react-images';


const Title = styled.h2`
  font-size: 1.5em;
  font-weight: 600;
  text-transform: capitalize;
  margin: 1rem 1rem 0.5rem 1rem;
`

class Puppies extends React.Component {
    constructor() {
        super();

        this.state = {
            lightboxIsOpen: false,
            currentImage: 0,
            images: []
        };


        this.closeLightbox = this.closeLightbox.bind(this);
        this.gotoNext = this.gotoNext.bind(this);
        this.gotoPrevious = this.gotoPrevious.bind(this);
        this.gotoImage = this.gotoImage.bind(this);
        this.handleClickImage = this.handleClickImage.bind(this);
        this.openLightbox = this.openLightbox.bind(this);
    }

    openLightbox(id, event) {
        event.preventDefault();
        let index = this.state.images.findIndex(a => a.id === id);
        this.setState({
            currentImage: index,
            lightboxIsOpen: true,
        });
    }

    closeLightbox() {
        this.setState({
            currentImage: 0,
            lightboxIsOpen: false,
        });
    }

    gotoPrevious() {
        this.setState({
            currentImage: this.state.currentImage - 1,
        });
    }

    gotoNext() {
        this.setState({
            currentImage: this.state.currentImage + 1,
        });
    }

    gotoImage(index) {
        this.setState({
            currentImage: index,
        });
    }

    handleClickImage() {
        if (this.state.currentImage === this.props.images.length - 1) return;

        this.gotoNext();
    }


    render() {
        const galleries = this.props.data.allContentfulGallery.edges;
        this.props.data.allContentfulGallery.edges.map(gallery => {gallery.node.images.map(image => {
            this.state.images.push( {
                src: image.sizes.src,
                srcSet: image.sizes.srcSet,
                caption: image.description,
                id: image.id
            })
        })});
        return (
            <Layout>
                <Container>
                    {galleries.map(({node: gallery}) => (
                        <div key={gallery.id}>
                            <Title>{gallery.name}</Title>
                            <CardList key={gallery}>
                                {gallery.images.map((image, i) => (
                                    <GalleryCard
                                        key={i}
                                        image={image}
                                        onClick={(e) => this.openLightbox(image.id, e)}
                                    />
                                ))}
                            </CardList>
                        </div>
                    ))}
                </Container>
                <Lightbox
                    currentImage={this.state.currentImage}
                    images={this.state.images}
                    isOpen={this.state.lightboxIsOpen}
                    onClickImage={this.handleClickImage}
                    onClickNext={this.gotoNext}
                    onClickPrev={this.gotoPrevious}
                    onClickThumbnail={this.gotoImage}
                    onClose={this.closeLightbox}
                />
            </Layout>
        )
    }
}

export const query = graphql`
    query PuppiesQuery {
        allContentfulGallery(
            limit: 1000
            filter: { slug: { regex : "/welpen-/" } } 
        ) {
            edges {
                node {
                    name
                    id
                    slug
                    images{
                        id
                        description
                        sizes(maxWidth: 300) {
                            ...GatsbyContentfulSizes_withWebp_noBase64
                        }
                    }
                }
            }
        }

    }
`

export default Puppies
